import styles from './TestQuestion.module.scss';
import { Trash, Copy, Plus, Xmark } from 'iconoir-react';
import NormalInput from '../../../inputs/NormalInput';
import { useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import Checkbox from '../../../inputs/Checkbox';

const TestQuestion = forwardRef(({
    defaultTitle = '', 
    defaultType = 'exact', 
    defaultOpen = false, 
    defaultCaseSensitive = false, 
    defaultAlternatives = [], 
    defaultCorrectAlternatives = [],
    defaultAnswer = [''],     
    onRemove
}, ref) => {
    const [ title, setTitle ] = useState(defaultTitle);
    const [ type, setType ] = useState(defaultType);
    const [ open, setOpen ] = useState(defaultOpen);
    const [ caseSensitive, setCaseSensitive ] = useState(defaultCaseSensitive);
    const [ answer, setAnswer ] = useState(defaultAnswer);
    const [ alternatives, setAlternatives ] = useState(defaultAlternatives);
    const [ correctAlternatives, setCorrectAlternatives ] = useState(defaultCorrectAlternatives);

    // ---------------
    //  Question data
    // ---------------

    useImperativeHandle(ref, () => ({
        getQuestionData: () => ({
            title: title,
            type: type,
            open: open,
            case_sensitive: caseSensitive,
            answer: answer,
            alternatives: alternatives,
            correct_alternatives: correctAlternatives
        })
    }));

    // --------------
    //  Alternatives
    // --------------

    function addAlternative() {
        setAlternatives(current => [...current, '']);
    }

    function removeAlternative(index) {
        if (alternatives.length === 1) return;
        setAlternatives(current => current.filter((_, i) => i !== index));
    }

    // -------
    //  Types
    // -------

    // Reset answer, alternatives and correct alternatives on type change
    const changeType = (newType) => {
        const typeChanged = newType !== type;
        setType(newType);

        if (type === 'exact') {
            setAnswer(!typeChanged ? defaultAnswer : ['']);
            setAlternatives([]);
        } else if (type === 'open') {
            setAnswer([]);
            setAlternatives([]);
        } else if (type === 'multiple-choice') {
            setAnswer([]);
            setAlternatives(!typeChanged ? defaultAlternatives : ['']);
            setCorrectAlternatives(!typeChanged ? defaultCorrectAlternatives : [0]);
        } else if (type === 'checkboxes') {
            setAnswer([]);
            setAlternatives(!typeChanged ? defaultAlternatives : ['']);
            setCorrectAlternatives(!typeChanged ? defaultCorrectAlternatives : []);
        }
    }

    // ----------------
    //  Answer content
    // ----------------

    const answerContent = {
        'exact': <>
            { answer.map((answer, index) => {
                return (
                    <span className={styles.answerInputRemovable} key={index}>
                        <span className={styles.inputWrapper}>
                            <NormalInput 
                                placeholder="Skriv rätt svar" 
                                type="text" 
                                value={answer}
                                onChange={e => { setAnswer(current => {
                                    const newAnswer = [...current];
                                    newAnswer[index] = e.target.value;
                                    return newAnswer;
                                }) }}
                            />
                        </span>
                        <Xmark onClick={() => short_removeAnswer(index)} />
                    </span>
                );
            }) }
            <span className={styles.addAnswer} onClick={short_addAnswer}>
                <Plus />
                Lägg till rätt svar
            </span>
            <div className={styles.answerOptions}>
                <Checkbox onChange={() => { setCaseSensitive(current => !current) }}>Känslig för gemener/versaler</Checkbox>
            </div>
        </>,
        'open': <><textarea className={styles.textarea} placeholder='Öppet textsvar' disabled /></>,
        'multiple-choice': <>
            { alternatives.map((_, index) => {
                return (
                    <span className={styles.radioInputRemovable} key={index}>
                        <span className={styles.radioWrapper}>
                            <div 
                                className={`${styles.radio}  ${correctAlternatives.includes(index) ? styles.selected : ''}`} 
                                onClick={() => { setCorrectAlternatives(() => [index]) }}
                            ></div>
                            <input 
                                type="text" 
                                placeholder='Skriv alternativtext' 
                                value={alternatives[index]} 
                                onChange={e => { setAlternatives(current => {
                                    const newAlternatives = [...current];
                                    newAlternatives[index] = e.target.value;
                                    return newAlternatives;
                                });}}
                            />
                        </span>
                        <Xmark onClick={() => removeAlternative(index)} />
                    </span>
                );
            }) }
            <span className={styles.addAnswer} onClick={addAlternative}>
                <Plus />
                Lägg till alternativ
            </span>
            <div className={styles.answerOptions}>
                <Checkbox onChange={() => { setOpen(current => !current) }} defaultChecked={defaultOpen}>Öppet svar</Checkbox>
            </div>
        </>,
        'checkboxes': <>
            { alternatives.map((_, index) => {
                return (
                    <span className={styles.checkboxInputRemovable} key={index}>
                        <span className={styles.checkboxWrapper}>
                            <div 
                                className={`${styles.checkbox}  ${correctAlternatives.includes(index) ? styles.selected : ''}`} 
                                onClick={() => { setCorrectAlternatives(current => {
                                    const newAlternatives = [...current];
                                    if (newAlternatives.includes(index)) {
                                        newAlternatives.splice(newAlternatives.indexOf(index), 1);
                                    } else {
                                        newAlternatives.push(index);
                                    }

                                    return newAlternatives;
                                }) }}
                            ></div>
                            <input 
                                type="text" 
                                placeholder='Skriv alternativtext' 
                                value={alternatives[index]} 
                                onChange={e => { setAlternatives(current => {
                                    const newAlternatives = [...current];
                                    newAlternatives[index] = e.target.value;

                                    return newAlternatives;
                                });}}
                            />
                        </span>
                        <Xmark onClick={() => removeAlternative(index)} />
                    </span>
                );
            }) }
            <span className={styles.addAnswer} onClick={addAlternative}>
                <Plus />
                Lägg till alternativ
            </span>
            <div className={styles.answerOptions}>
                <Checkbox onChange={() => { setOpen(current => !current) }} defaultChecked={defaultOpen}>Öppet svar</Checkbox>
            </div>
        </>
    }

    // ------------
    //  Short text
    // ------------

    function short_addAnswer() {
        setAnswer(current => [...current, '']);
    }

    function short_removeAnswer(index) {
        if (answer.length === 1) return;
        setAnswer(current => current.filter((_, i) => i !== index));
    }

    // --------------------------------
    //  Multiple-choice and Checkboxes
    // --------------------------------

    // Make sure correctAlternatives is in range of alternatives
    useEffect(() => {
        if (type === 'multiple-choice') {
            setCorrectAlternatives((prevCorrectAlternatives) => {
                if (prevCorrectAlternatives.length === 0 || 
                    prevCorrectAlternatives[0] >= alternatives.length) {
                    return [0];
                }

                return prevCorrectAlternatives;
            });
        } else if (type === 'checkboxes') {
            setCorrectAlternatives((prevCorrectAlternatives) => {
                const newAlternatives = [...prevCorrectAlternatives];

                newAlternatives.forEach((alternative, index) => {
                    if (alternative >= alternatives.length) {
                        newAlternatives.splice(index, 1);
                    }
                });

                return newAlternatives;
            });
        }
    }, [alternatives, type]);

    // -----------
    //  Component
    // -----------

    return (
        <div className={styles.question}>
            <div className={styles.main}>
                <div className={styles.title}>
                    <input type="text" className={styles.titleInput} placeholder='Skriv fråga' value={title} onChange={e => { setTitle(e.target.value); }} />
                    <select className={styles.typeSelect} defaultValue={defaultType} onChange={e => { changeType(e.target.value); }}>
                        <option value="exact">Exakt textsvar</option>
                        <option value="open">Öppet textsvar</option>
                        <option value="multiple-choice">Flerval</option>
                        <option value="checkboxes">Kryssrutor</option>
                    </select>
                </div>
                <div className={styles.answerContent}>
                    {answerContent[type]}
                </div>
            </div>
            <div className={styles.aside}>
                <Trash onClick={onRemove} />
                <Copy />
            </div>
        </div>
    );
})

export default TestQuestion;
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/account/Login';
import Signup from './pages/account/Signup';
import Logout from './pages/account/Logout';
import VerifyEmail from './pages/account/VerifyEmail';
import ForgotPassword from './pages/account/ForgotPassword';
import NewPassword from './pages/account/NewPassword';
import Explore from './pages/Explore';
import Integrity from './pages/Integrity';
import Conditions from './pages/Conditions';
import Dashboard from './pages/Dashboard';
import CreateSet from './pages/create/set/CreateSet';
import EditSet from './pages/edit/set/EditSet';
import CreateTest from './pages/create/test/CreateTest';
import EditTest from './pages/edit/test/EditTest';
import Set from './pages/set/Set';
import Join from './pages/test/Join';
import Host from './pages/test/Host';
import Student from './pages/test/Student';
import Results from './pages/test/Results';
import Done from './pages/test/Done';
import Save from './pages/test/Save';
import NotFound from './pages/error/NotFound';
import Forbidden from './pages/error/Forbidden';
import InternalServerError from './pages/error/InternalServerError';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
const trackingId = 'G-12GGHV0H8W';
ReactGA.initialize(trackingId);

function Analytics() {
    const location = useLocation();
    
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search);
    }, [location]);
}

function App() {
    return (
        <BrowserRouter>
            <Analytics />
            <Routes>
                <Route path="/" element={<Home />} />
                
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/new-password" element={<NewPassword />} />

                <Route path="/explore" element={<Explore />} />

                <Route path="/integrity" element={<Integrity />} />
                <Route path="/conditions" element={<Conditions />} />

                <Route path="/dashboard/*" element={<Dashboard />} />
                <Route path="/create/set" element={<CreateSet />} />
                <Route path="/edit/set/*" element={<EditSet />} />
                <Route path="/create/test" element={<CreateTest />} />
                <Route path="/edit/test/*" element={<EditTest />} />

                <Route path="/set/:set_id" element={<Set />} />
                <Route path="/set/:set_id/:type" element={<Set />} />

                <Route path="/test/join" element={<Join />} />
                <Route path="/test/join/:live_id" element={<Join />} />
                <Route path="/test/host/:live_id" element={<Host />} />
                <Route path="/test/student" element={<Student />} />
                <Route path="/test/results" element={<Results />} />
                <Route path="/test/done" element={<Done />} />
                <Route path="/test/save/:save_id" element={<Save />} />

                <Route path="*" element={<NotFound />} />
                <Route path="/403" element={<Forbidden />} />
                <Route path="/500" element={<InternalServerError />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
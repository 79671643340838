import TestCard from "../../../tests/cards/TestCard";
import { useState, useEffect } from 'react';
import axios from 'axios';
import LazyLoad from "react-lazy-load";
import styles from "../Dashboard.module.scss";
import { Menu } from "iconoir-react";
import ErrorMessage from "../../../popups/message/ErrorMessage";

function TestScreen({ onSidebarOpen }) {
    const [ tests, setTests ] = useState([]);
    const [ renderedTests, setRenderedTests ] = useState([]);
    const [ testSaves, setTestSaves ] = useState([]);
    const [ error, setError ] = useState(null);

    // On first render
    useEffect(() => {
        getTests();
        getTestSaves();
    }, []);
        

    const getTests = () => {
        axios.get(`https://api.studian.se/test/get/by-creator?active=true`, {
            withCredentials: true
        }).then(response => {
            setTests(response.data.tests);
            setRenderedTests(response.data.tests);
        }).catch(error => {
            console.error('Error with API call:', error.message);
            setError(error.response?.data?.error || 'Något gick fel. Kontakta oss om felet kvarstår!');
        });
    }

    const getTestSaves = () => {
        axios.get('https://api.studian.se/test/save/get/by-creator?active=true', {
            withCredentials: true
        }).then(response => {
            setTestSaves(response.data.saves);
        }).catch(error => {
            console.error('Error with API call:', error.message);
            setError(error.response?.data?.error || 'Något gick fel. Kontakta oss om felet kvarstår!');
        });
    }

    return (
        <>  
            { error && <ErrorMessage message={error} /> }
            { tests.length > 0 ? 
                (
                    <>
                        <span className={styles.navTitle}>
                            <Menu onClick={onSidebarOpen} />
                            <h2 style={{ marginBlock: '1rem' }}>Dina läxförhör</h2>
                        </span>
                        <div className={styles.testCardWrapper}>
                            { renderedTests.map((test) => (
                                <TestCard 
                                    key={test.id} 
                                    testData={test} 
                                    testSaves={testSaves[test.id]} 
                                    refreshTests={getTests} 
                                    refreshTestSaves={getTestSaves} 
                                />
                            )) }
                        </div>
                    </>
                ) : (
                    <div className={styles.noContent}>
                        <LazyLoad className={styles.lazyLoad}>
                            <img src="/assets/images/graphics/dashboard/tests.svg" alt="Skapa ditt första läxförhör" />
                        </LazyLoad>
                        <h1>Skapa ditt första läxförhör!</h1>
                    </div>
                )
            }
        </>
    );
}

export default TestScreen;
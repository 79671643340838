import React, { forwardRef } from 'react';
import styles from './BoxButton.module.scss';
import SyncLoader from 'react-spinners/SyncLoader';

const BoxButton = forwardRef(({ children, onClick, disabled = false, loading = false, className }, ref) => (
    <button ref={ref} className={`${styles.button} ${className}`} onClick={onClick} disabled={disabled}>
        {loading ? null : children}
        <SyncLoader loading={loading} size={10} color="#fff" />
    </button>
));

export default BoxButton;
import styles from './HomeHeader.module.scss';
import PrimaryButton from '../buttons/PrimaryButton';

function HomeHeader() {
    return (
        <header className={styles.header}>
            <div className={styles.headerContent}>
                <div className={styles.headerContentBlobs}>
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#FE654F" d="M52.9,-64C67.9,-62,78.8,-45.6,75.4,-30.5C71.9,-15.5,54.2,-1.8,45.4,11.5C36.6,24.9,36.8,37.9,30.8,45.1C24.7,52.3,12.3,53.7,1.6,51.5C-9.2,49.3,-18.4,43.6,-34.1,39.6C-49.8,35.5,-72.1,33.1,-81.9,22.1C-91.7,11.1,-89.1,-8.5,-80.5,-23.5C-72,-38.5,-57.5,-48.9,-43.1,-51.1C-28.7,-53.3,-14.4,-47.3,2.3,-50.5C19,-53.7,38,-66,52.9,-64Z" transform="translate(100 100)" />
                    </svg>
                    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#FE654F" d="M34,-45.3C49,-35.9,69.5,-32.6,78.3,-21.7C87,-10.8,83.9,7.7,78.4,25.7C72.8,43.6,64.9,61,51.4,65.4C37.9,69.8,19,61.4,0.2,61.1C-18.5,60.7,-37,68.5,-48.4,63.4C-59.8,58.3,-64.2,40.3,-61,25.6C-57.9,10.9,-47.3,-0.5,-40,-9.9C-32.7,-19.3,-28.8,-26.7,-22.6,-39.1C-16.5,-51.5,-8.3,-68.8,0.6,-69.7C9.5,-70.5,19,-54.8,34,-45.3Z" transform="translate(100 100)" />
                    </svg>
                </div>
                <div className={styles.headerContentText}>
                    <h1>Ta din undervisning till <i>nästa nivå</i></h1>
                    <p>Studian är det digitala verktyget som hjälper lärare att engagera sina elever och göra undervisningen mer inspirerande. Skapa flashcards, håll läxförhör och gör lärandet roligare – allt på en plats.</p>
                    <div className={styles.headerContentButtons}>
                        <PrimaryButton onClick={() => { window.location.href = '/signup' }}>Kom igång</PrimaryButton>
                    </div>
                </div>
            </div>
            <div className={styles.headerImage}>
                <img src="/assets/images/graphics/landing_page/Josefine.webp" alt="" />
                <img src="/assets/images/graphics/landing_page/student.webp" alt="" />
            </div>
        </header>
    );
}

export default HomeHeader;
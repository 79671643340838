import SetCardWrapperLg from "../../../sets/cards/SetCardWrapperLg";
import PublicSetCard from "../../../sets/cards/PublicSetCard";
import SearchInput from "../../../inputs/SearchInput";
import { useState, useEffect } from 'react';
import axios from 'axios';
import LazyLoad from "react-lazy-load";
import styles from "../Dashboard.module.scss";
import { Menu } from "iconoir-react";

function SetScreen({ onSidebarOpen }) {
    const [ sets, setSets ] = useState([]);
    const [ renderedSets, setRenderedSets ] = useState([]);
    const [ search, setSearch ] = useState('');
    const [ searchIterationId, setSearchIterationId ] = useState(0);
    
    function retrieveSets() {
        axios.get(`https://api.studian.se/set/get/by-creator?active=true`, {
            withCredentials: true
        }).then(response => {
            setSets(response.data.sets);
            setRenderedSets(response.data.sets);
        }).catch(error => {
            console.error('Error with API call:', error.message);
        });
    }

    useEffect(() => {
        retrieveSets();
    }, []);

    // Search sets
    useEffect(() => {
        let filteredSets = [];
        
        if (search.length > 0) {
            sets.forEach(set => {
                if (set.name.toLowerCase().includes(search.toLowerCase())) {
                    filteredSets.push(set);
                }
            });
        } else {
            filteredSets = sets;
        }
        
        setRenderedSets(filteredSets);
        setSearchIterationId(Date.now());
    }, [search, sets]);

    return (
        <>  
            { sets.length > 0 ? 
                (
                    <>
                        <span className={styles.navTitle}>
                            <Menu onClick={onSidebarOpen} />
                            <h2 style={{ marginBlock: '1rem' }}>Dina läxor</h2>
                        </span>
                        <div style={{ marginBottom: '2rem' }}>
                            <SearchInput placeholder="Sök efter läxa" onChange={(e) => setSearch(e.target.value)} value={search} />
                        </div>
                        <SetCardWrapperLg>
                            { renderedSets.map((set) => <PublicSetCard key={`${set.id}-${searchIterationId}`} setData={set} manage={true} onDelete={retrieveSets} />) }
                        </SetCardWrapperLg>
                    </>
                ) : (
                    <div className={styles.noContent}>
                        <LazyLoad className={styles.lazyLoad}>
                            <img src="/assets/images/graphics/dashboard/sets.svg" alt="Skapa din första läxa" />
                        </LazyLoad>
                        <h1>Skapa din första läxa!</h1>
                    </div>
                )
            }
        </>
    );
}

export default SetScreen;
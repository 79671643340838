import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import styles from './DeleteSaveModal.module.scss';
import PrimaryButton from "../../buttons/PrimaryButton";
import SecondaryButton from "../../buttons/SecondaryButton";
import { Trash, WarningTriangle } from "iconoir-react/regular";
import ErrorMessage from "../../popups/message/ErrorMessage";
import axios from 'axios';
import { useState } from 'react';

const contentStyle = {
    borderRadius: '1rem',
    padding: '2rem',
    width: 'min(90%, 500px)',
}

function DeleteSaveModal({ saveId, onDelete }) {
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    function deleteSave(closeModal) {
        setLoading(true);

        axios.delete(`https://api.studian.se/test/save/manage/delete?save_id=${saveId}`, {
            withCredentials: true
        }).then(() => {
            closeModal();
            onDelete();
        }).catch(error => {
            setLoading(false);
            const errorMessage = error.response?.data?.message || 'Något gick fel. Kontakta oss om felet kvarstår.';
            setError(errorMessage);
            console.error('Error with API call: ', error);
        });
    }

    return (
        <Popup 
            modal 
            trigger={
                <span>
                    <Popup 
                        trigger={<Trash />}
                        on={['hover', 'focus']}
                        position={'bottom center'}
                        nested
                    >
                        <span>Radera</span>
                    </Popup>
                </span>
            } 
            position="bottom center"
            {...{contentStyle}}
            nested
        >
            { closeDeleteModal => (
                <div className={styles.content}>
                    <WarningTriangle className={styles.icon} />
                    <h1>Radera läxförhöret?</h1>
                    <p>Det sparade läxförhöret tas bort permanent och går inte att få tillbaka.</p>
                    { error && <ErrorMessage message={error} /> }
                    <div className={styles.buttonWrapper}>
                        <SecondaryButton onClick={() => { closeDeleteModal() }}>Nej</SecondaryButton>
                        <PrimaryButton onClick={() => { deleteSave(closeDeleteModal) }} loading={loading}>Ja, radera</PrimaryButton>
                    </div>
                </div>
            ) }
        </Popup>
    );
}

export default DeleteSaveModal;
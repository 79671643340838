import styles from './CreateTest.module.scss';
import Navbar from "../../../components/navbars/Navbar";
import Auth from "../../../components/auth/Auth";
import ContainerSm from '../../../containers/ContainerSm';
import Section from '../../../containers/Section';
import CreateTestForm from '../../../components/pages/create/tests/CreateTestForm';
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import ErrorMessage from "../../../components/popups/message/ErrorMessage";
import { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

function CreateTest() {
    const [ error, setError ] = useState('');
    const createTestFormRef = useRef(null);
    const [ urlParams ] = useSearchParams();
    const [ importedName, setImportedName ] = useState(null);
    const [ importedQuestions, setImportedQuestions ] = useState(null);

    useEffect(() => {
        if (urlParams.get('import')) {
            axios.get(`https://api.studian.se/test/manage/generate-from-set?set_id=${urlParams.get('import')}`)
                .then(res => {
                    setImportedName(res.data.test.name);
                    setImportedQuestions(res.data.test.questions);
                })
                .catch(error => {
                    const message = error.response?.data?.error || 'Något gick fel. Kontakta oss om felet kvarstår!';
                    console.error('Error with API call: ', error);
                    setError(message);
                })
        }
    }, [urlParams]);

    const handleSubmit = () => {
        const questionsData = createTestFormRef.current.gatherQuestionsData();
        const testName = createTestFormRef.current.getTestName();
        const freezeMode = createTestFormRef.current.getFreezeMode();
        const showResults = createTestFormRef.current.getShowResults();
        
        // Call the API and create the test
        axios.post('https://api.studian.se/test/manage/create', {
            name: testName,
            freeze_mode: freezeMode,
            show_results: showResults,
            questions: questionsData
        }, {
            withCredentials: true
        }).then(() => {
            window.location.href = '/dashboard/tests';
        }).catch(error => {
            const message = error.response?.data?.error || 'Något gick fel. Kontakta oss om felet kvarstår!';
            setError(message);
        });
    }

    return (
        <div>
            <Auth redirect="/login">
                <Navbar />
                <ContainerSm>
                    <Section>
                        <div className={styles.header}>
                            <h2>Skapa läxförhör</h2>
                            <PrimaryButton onClick={handleSubmit}>Skapa</PrimaryButton>
                        </div>
                        { error && <ErrorMessage message={error} /> }
                        <CreateTestForm 
                            ref={createTestFormRef} 
                            defaultName={importedName || ''}
                            defaultQuestions={importedQuestions || []} 
                        />
                    </Section>
                </ContainerSm>
            </Auth>
        </div>
    );
}

export default CreateTest;
import axios from 'axios';
import { useEffect } from 'react';

function Logout() {
    useEffect(() => {
        axios.post('https://api.studian.se/auth/logout', {}, { withCredentials: true }).then(() => {
            window.location.href = '/';
        })
    }, []);
}

export default Logout;
import styles from './Error.module.scss';
import Navbar from '../../components/navbars/Navbar';
import LazyLoad from 'react-lazy-load';

function InternalServerError() {
    return (
        <div>
            <Navbar />
            <div className={styles.errorPage}>
                <div className={styles.errorPageImage}>
                    <LazyLoad>
                        <img src="/assets/images/graphics/error_pages/500.webp" alt="" />
                    </LazyLoad>
                </div>
                <div className={styles.errorPageText}>
                    <span className={styles.errorPageError}>Error 500</span>
                    <h1 className={styles.errorPageTitle}>Något gick fel här bakom!</h1>
                    <p className={styles.errorPageParagraph}>Något gick fel på servern. Klicka <a href="/">här</a> för att komma tillbaka till förstasidan.</p>
                </div>
            </div>
        </div>
    );
}

export default InternalServerError;
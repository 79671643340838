import styles from './WarningMessage.module.scss';
import { WarningTriangle } from 'iconoir-react';

function WarningMessage({ message }) {
    return (
        <div className={styles.warningMessage}>
            <WarningTriangle className={styles.icon} />
            <span className={styles.message}>{message}</span>
        </div>
    );
}

export default WarningMessage;
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import styles from './RatePopup.module.scss';
import { StarSolid } from "iconoir-react";
import SecondaryButton from "../../buttons/SecondaryButton";
import { useEffect, useState } from "react";
import axios from "axios";
import Auth from "../../auth/Auth";
import AuthNot from "../../auth/AuthNot";

const contentStyle = {
    borderRadius: '.5rem',
    padding: '0',
    width: 'min(90%, 200px)',
    boxShadow: 'none'
};

const arrowStyle = {
    color: '#fff'
};

function RatePopup({ setId }) {
    const [ hoverRating, setHoverRating ] = useState(0);
    const [ lastRating, setLastRating ] = useState(0);

    useEffect(() => {
        axios.get(`https://api.studian.se/set/rating/get-user-rating?set_id=${setId}`, {
            withCredentials: true
        }).then(response => {
            if (response.data.rating > 0) {
                setLastRating(response.data.rating);
            }
        }).catch(error => {
            console.error('Error with API call: ', error);
        })
    }, [setId]);

    function handleClick(rating) {
        axios.post('https://api.studian.se/set/rating/rate', {
            set_id: setId,
            rating: rating
        }, {
            withCredentials: true
        }).then(() => {
            setLastRating(rating);
        }).catch(error => {
            console.error('Error with API call: ', error);
        });
    }

    return (
        <Popup
            position="bottom center"
            {...{ contentStyle, arrowStyle }}
            trigger={
                <SecondaryButton className={styles.rateButton}>Betygsätt</SecondaryButton>
            }
        >
            {close => (
                <div className={styles.content}>
                    <AuthNot>Logga in för att betygsätta</AuthNot>
                    <Auth>
                        <div className={styles.wrapper}>
                            {[1, 2, 3, 4, 5].map(rating => {
                                return (
                                    <div
                                        key={rating}
                                        className={styles.star}
                                        onMouseEnter={() => setHoverRating(rating)}
                                        onMouseLeave={() => setHoverRating(0)}
                                        onClick={() => {
                                            close();
                                            handleClick(rating);
                                        }}
                                    >
                                        <StarSolid
                                            className={rating <= (hoverRating || lastRating) ? styles.filled : ''}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </Auth>
                </div>
            )}
        </Popup>
    );
}

export default RatePopup;
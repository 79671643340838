import styles from './Checkbox.module.scss';
import { Check } from 'iconoir-react';

function Checkbox({ children, checked, defaultChecked, onChange, name }) {
    return (
        <label className={styles.checkbox}>
            <input type="checkbox" name={name} checked={checked} defaultChecked={defaultChecked} onChange={onChange} />
            <span className={styles.checkmark}>
                <Check />
            </span>
            <span className={styles.label}>
                {children}
            </span>
        </label>
    );
}

export default Checkbox;
import Navbar from '../../components/navbars/Navbar';
import GradientContainer from '../../containers/GradientContainer';
import LoginBox from '../../components/boxes/welcome/LoginBox';
import axios from 'axios';
import { useState } from 'react';
import AuthNot from '../../components/auth/AuthNot';

function Login() {
    const [ error, setError ] = useState(null);
    const [ buttonDisabled, setButtonDisabled ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    async function handleSubmit(username, password) {
        setButtonDisabled(true);
        setLoading(true);

        setTimeout(() => {
            axios.post('https://api.studian.se/auth/login', {
                username: username,
                password: password,
            }, {
                withCredentials: true
            }).then(() => {
                window.location.href = '/dashboard';
            }).catch(error => {
                const message = error.response?.data?.error || 'Något gick fel. Försök igen eller kontakta oss.';
                setError(message);
                setButtonDisabled(false);
                setLoading(false);
            });
        }, 1000);
    }

    return (
        <div>
            <AuthNot redirect="/dashboard">
                <Navbar />
                <GradientContainer centerContent={true}>
                    <LoginBox onSubmit={handleSubmit} errorMessage={error} buttonDisabled={buttonDisabled} loading={loading} />
                </GradientContainer>
            </AuthNot>
        </div>
    );
}

export default Login;
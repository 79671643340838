import styles from './Student.module.scss';
import Navbar from '../../components/navbars/Navbar';
import ContainerSm from '../../containers/ContainerSm';
import { ArrowLeftCircle, ArrowRightCircle, Check, CheckCircle } from 'iconoir-react';
import Popup from 'reactjs-popup';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ErrorMessage from '../../components/popups/message/ErrorMessage';

function Student() {
    const [ questions, setQuestions ] = useState([]);
    const [ globalIndex, setGlobalIndex ] = useState(0);
    const [ switching, setSwitching ] = useState(0); // -1 for previous, 1 for next
    const [ answers, setAnswers ] = useState(null);
    const [ freezeMode, setFreezeMode ] = useState(false);
    const [ frozen, setFrozen ] = useState(false);
    const [ error, setError ] = useState(null);

    // Get questions
    useEffect(() => {
        axios.get('https://api.studian.se/test/student/get-questions', {
            withCredentials: true
        }).then(response => {
            setQuestions(response.data.questions);
        }).catch(error => {
            setError(error.response?.data?.message || 'Något gick fel. Försök igen eller kontakta oss.');
        });
    }, [ setQuestions ]);

    // Get progress
    useEffect(() => {
        axios.get('https://api.studian.se/test/student/get-progress', {
            withCredentials: true
        }).then(response => {
            // Extract answers, so unanswered questions are not percieved as empty answers
            const progress = response.data.progress;
            const extractedProgress = [];

            progress.forEach((answer, index) => {
                if (answer.length > 0) {
                    extractedProgress[index] = answer;
                }
            });

            setAnswers(extractedProgress);
        }).catch(error => {
            setError(error.response?.data?.message || 'Något gick fel. Försök igen eller kontakta oss.');
        });
    }, []);

    // Check freeze mode
    useEffect(() => {
        axios.get('https://api.studian.se/test/student/is-freeze-mode', {
            withCredentials: true
        }).then(res => {
            setFreezeMode(res.data.freeze_mode);
        }).catch(error => {
            setError(error.response?.data?.message || 'Något gick fel. Försök igen eller kontakta oss.');
        });
    });

    // Freeze student
    function checkFrozen() {
        axios.get('https://api.studian.se/test/student/is-frozen', {
            withCredentials: true
        }).then(res => {
            setFrozen(res.data.frozen);
        }).catch(error => {
            setError(error.response?.data?.message || 'Något gick fel. Försök igen eller kontakta oss.');
        });
    }

    const freeze = useCallback(() => {
        if (!freezeMode) return;
        setFrozen(true);
        axios.put('https://api.studian.se/test/student/freeze', {}, {
            withCredentials: true
        });
    }, [freezeMode]);

    useEffect(() => {
        checkFrozen(); // Check frozen on load
        const checkFrozenInterval = setInterval(checkFrozen, 1000); // Check if student is unfrozen every second
        window.addEventListener('blur', freeze); // Freeze student if window is unfocused

        return () => {
            clearInterval(checkFrozenInterval); // Clear interval on unmount
            window.removeEventListener('blur', freeze); // Remove event listener on unmount
        }
    }, [freeze, freezeMode]);

    // Handle type and answer component
    const answerComponent_exactOpen = () => {
        return (
            <div className={styles.answerExactOpen}>
                <textarea 
                    placeholder='Skriv ditt svar' 
                    autoFocus 
                    onChange={(e) => {
                        setAnswers(current => {
                            if (frozen) return current;
                            const newAnswers = [...current];
                            newAnswers[globalIndex] = [e.target.value];
                            return newAnswers;
                        });
                    }}
                    disabled={frozen}
                    value={(answers && answers[globalIndex]?.[0]) || ''}
                ></textarea>
            </div>
        );
    }

    const answerComponent_multiplechoice = () => {
        return (
            <div className={styles.answerMultiplechoice}>
                { questions[globalIndex]?.alternatives.map((alternative, index) => {
                    return (
                        <span 
                            className={`${styles.alternative} ${(answers && answers[globalIndex]?.[0]) === index ? styles.selected : ''}`} 
                            key={index} 
                            onClick={() => setAnswers(current => {
                                if (frozen) return current;
                                const newAnswers = [...current];
                                newAnswers[globalIndex] = [index];
                                return newAnswers;
                            })}
                        >
                            <span className={styles.radio}></span>
                            <span className={styles.label}>{ alternative }</span>
                        </span>
                    );
                }) }
            </div>
        );
    }

    const answerComponent_checkboxes = () => {
        return (
            <div className={styles.answerCheckboxes}>
                { questions[globalIndex]?.alternatives.map((alternative, index) => {
                    return (
                        <span 
                            className={`${styles.alternative} ${answers[globalIndex]?.includes(index) ? styles.selected : ''}`} 
                            key={index} 
                            onClick={() => handleCheckboxClick(index)}
                        >
                            <span className={styles.checkbox}><Check /></span>
                            <span className={styles.label}>{ alternative }</span>
                        </span>
                    );
                }) }
            </div>
        );
    }

    const answerComponent = () => {
        switch (questions[globalIndex]?.type) {
            case 'exact':
                return answerComponent_exactOpen();
            case 'open':
                return answerComponent_exactOpen();
            case 'multiple-choice':
                return answerComponent_multiplechoice();
            case 'checkboxes':
                return answerComponent_checkboxes();
            default:
                return <Skeleton width={'100%'} height={100} />
        }
    }

    // Handle checkboxes
    const handleCheckboxClick = (index) => {
        if (frozen) return;

        setAnswers(current => {
            const newAnswers = [...current];
            const selected = newAnswers[globalIndex] || [];
    
            newAnswers[globalIndex] = selected.includes(index)
                ? selected.filter(i => i !== index)
                : [...selected, index];
    
            return newAnswers;
        });
    };

    // Handle submit answers
    const handleSubmit = () => {
        if (frozen) return;

        axios.post('https://api.studian.se/test/student/submit-answers', {
            'answers': answers
        }, {
            withCredentials: true
        }).catch(error => {
            setError(error.response?.data?.message || 'Något gick fel. Försök igen eller kontakta oss.');
        });
    };

    // Handle navigation
    const handleNext = () => {
        if (!questions) return;
        if (globalIndex === questions.length - 1 || frozen) return;

        setSwitching(1);
        setGlobalIndex(globalIndex + 1);
        handleSubmit();

        setTimeout(() => {
            setSwitching(0);
        }, 100);
    };

    const handlePrev = () => {
        if (!questions) return;
        if (globalIndex === 0 || frozen) return;

        setSwitching(-1);
        setGlobalIndex(globalIndex - 1);
        handleSubmit();

        setTimeout(() => {
            setSwitching(0);
        }, 100)
    };

    const handleDone = async () => {
        if (frozen) return;

        try {
            // Submit answers
            await axios.post('https://api.studian.se/test/student/submit-answers', {
                'answers': answers
            }, {
                withCredentials: true
            });

            // Check if results are shown
            let resultsShown = false;
            const resultsShownRequest = await axios.get('https://api.studian.se/test/student/are-result-shown', {
                withCredentials: true
            });
            if (resultsShownRequest.status === 200 && resultsShownRequest.data.results_shown) resultsShown = true;

            // Finish test and leave session
            await axios.put('https://api.studian.se/test/student/finish-test', {}, {
                withCredentials: true
            });

            // Redirect
            const redirectUrl = resultsShown ? '/test/results' : '/test/done';
            window.location.href = redirectUrl;
        } catch (error) {
            setError(error.response?.data?.message || 'Något gick fel. Försök igen eller kontakta oss.');
        }
    }

    // Handle freezing
    const freezeModal = () => {
        return (
            <Popup
                modal
                open
                contentStyle={{
                    borderRadius: '1rem',
                    width: 'min(90%, 600px)'
                }}
                closeOnDocumentClick={false}
            >
                <div className={styles.freezeModalContent}>
                    <img className={styles.illustration} src="/assets/images/graphics/illustrations/ice-cubes.webp" alt="Du är fryst" />
                    <h1>Du är fryst</h1>
                    <span>Det verkar som att du lämnade förhöret. Om det var av misstag kan du be din lärare att släppa in dig igen.</span>
                </div>
            </Popup>
        );
    }

    return (
        <div>
            <Navbar />
            <ContainerSm>
                <div className={styles.container}>
                    <div className={`${styles.question} ${switching === -1 ? styles.previous : switching === 1 ? styles.next : ''}`}>
                        <h1>{ questions ? questions[globalIndex]?.title : <Skeleton width={300} /> }</h1>
                        <div className={`${styles.answerWrapper}`}>
                            { questions && answerComponent() }
                        </div>
                    </div>
                    { error && <ErrorMessage message={error} /> }
                    <div className={styles.navigation}>
                        <Popup
                            trigger={<ArrowLeftCircle onClick={handlePrev} />}
                            position="bottom center"
                            on={['hover']}
                            closeOnDocumentClick
                        >
                            <span>Gå tillbaka</span>
                        </Popup>
                        { questions && globalIndex + 1 === questions.length 
                            ? <Popup
                                trigger={<CheckCircle onClick={handleDone} />}
                                position="bottom center"
                                on={['hover']}
                                closeOnDocumentClick
                            >
                                <span>Färdig</span>
                            </Popup>
                            : <Popup
                                trigger={<ArrowRightCircle onClick={handleNext} />}
                                position="bottom center"
                                on={['hover']}
                                closeOnDocumentClick
                            >
                                <span>Nästa</span>
                            </Popup>
                        }
                    </div>
                </div>
            </ContainerSm>
            { frozen && freezeModal() }
        </div>
    );
}

export default Student;
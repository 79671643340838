import styles from './TestSavesModal.module.scss';
import { Archive, OpenInWindow, Xmark } from 'iconoir-react';
import Popup from 'reactjs-popup';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SyncLoader from 'react-spinners/SyncLoader';
import DeleteSaveModal from '../modals/DeleteSaveModal';

const contentStyle = {
    borderRadius: '1rem',
    padding: '2rem',
    width: 'min(90%, 800px)',
    overflow: 'hidden'
};

function TestSavesModal({ testSaves, refreshTestSaves }) {
    return (
        <Popup 
            modal 
            contentStyle={contentStyle} 
            trigger={
                <span>
                    <Popup
                        trigger={
                            <span className={styles.trigger}>
                                <Archive />
                                { testSaves !== undefined && testSaves.length > 0 
                                    ? <span className={styles.count}>{ testSaves.length }</span> 
                                    : null 
                                }
                            </span>
                        }
                        on={['hover', 'focus']}
                        position={'bottom center'}
                    >
                        <span>Avslutade läxförhör</span>
                    </Popup>
                </span>
            }
            closeOnDocumentClick={false}
            nested
        >
            {close => ( testSaves !== undefined && testSaves.length > 0 ?
                <><Xmark className={styles.close} onClick={close} />
                <div className={styles.content}>
                    <h1 className={styles.title}>
                        { testSaves !== undefined 
                            ? testSaves[0].test_name
                            : <Skeleton width={200} />
                        }
                    </h1>
                    <div className={styles.saveWrapper}>
                        <div className={styles.saveGrid}>
                            {  testSaves === undefined 
                                ? <div className={styles.loading}>
                                    <div><SyncLoader color="#FE654F" /></div>
                                </div>
                                : testSaves.map((testSave, index) => {
                                    return (
                                        <div className={styles.save} key={index}>
                                            <div className={styles.saveHeader}>
                                                <Archive className={styles.saveIcon} />
                                                <div className={styles.saveContent}>
                                                    <span className={styles.saveDate}>{ testSave.created_readable }</span>
                                                    <span className={styles.saveTag}>{ testSave.student_number } {testSave.student_number === 1 ? 'elev' : 'elever'}</span>
                                                </div>
                                            </div>
                                            <div className={styles.saveActions}>
                                                <Popup 
                                                    trigger={
                                                        <a href={`/test/save/${testSave.id}`}><OpenInWindow /></a>
                                                    }
                                                    on={['hover', 'focus']}
                                                    position={'bottom center'}
                                                >
                                                    <span>Öppna</span>
                                                </Popup>
                                                <DeleteSaveModal saveId={testSave.id} onDelete={refreshTestSaves} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div></>
            : <>
                <Xmark className={styles.close} onClick={close} />
                <span className={styles.noSaves}>Inga sparade läxförhör</span>
            </> )}
        </Popup>
    )
}

export default TestSavesModal;
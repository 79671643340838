import styles from './QuestionBox.module.scss';
import { Check } from 'iconoir-react';

function QuestionBox({ question, answer, keyProp, studentName, secondPerson }) {
    // Genitive form of student name
    const getStudentNameGenitive = () => {
        if (['s', 'x', 'z'].includes(studentName.slice(-1))) {
            return studentName;
        } else {
            return studentName + 's';
        }
    }

    // Display answer
    const displayAnswer = (f_Type, f_Answer) => {
        switch (f_Type) {
            case 'exact':
                return (
                    <div className={styles.answerWrapper}>
                        <div className={styles.inputAnswerWrapper}>
                            { f_Answer.map((answer, index) => (
                                <div key={index} className={styles.exactAnswer}>{answer}</div>
                            )) }
                        </div>
                    </div>
                );

            case 'open':
                return (
                    <div className={styles.answerWrapper}>
                        <div className={styles.inputAnswerWrapper}>
                            { f_Answer.map((answer, index) => (
                                <div key={index} className={styles.exactAnswer}>{answer}</div>
                            )) }
                        </div>
                    </div>
                );

            case 'multiple-choice':
                return (
                    <div className={styles.answerWrapper}>
                        <div className={styles.mcAnswerWrapper}>
                            { question.alternatives.map((alternative, index) => (
                                <div className={styles.mcAnswer} key={index}>
                                    <span className={`${styles.radio}  ${index === f_Answer[0] ? styles.checked : ''}`}></span>
                                    { alternative }
                                </div>
                            ))}
                        </div>
                    </div>
                );

            case 'checkboxes':
                return (
                    <div className={styles.answerWrapper}>
                        <div className={styles.checkboxAnswerWrapper}>
                            { question.alternatives.map((alternative, index) => (
                                <div className={styles.checkboxAnswer} key={index}>
                                    <span className={`${styles.checkbox}  ${f_Answer.includes(index) ? styles.checked : ''}`}>
                                        { f_Answer.includes(index) && <Check /> }
                                    </span>
                                    { alternative }
                                </div>
                            ))}
                        </div>
                    </div>
                );

            default:
                break;
        }
    }

    // Get correct answer
    const getCorrectAnswer = () => {
        if (question.type === 'multiple-choice' || question.type === 'checkboxes') {
            return question.correct_alternatives;
        } else {
            return question.answer;
        }
    }

    // Component
    return(
        <div 
            className={`${styles.questionBox} 
                ${(answer.status === 'correct' && question.type !== 'open') && styles.correct} 
                ${(answer.status === 'incorrect' && question.type !== 'open') && styles.incorrect}`
            } 
            key={keyProp}
        >
            <div className={styles.title}>{question.title}</div>
            <div className={styles.answer}>
                <i className={styles.answerType}>{secondPerson ? 'Ditt' : getStudentNameGenitive()} svar</i>
                { displayAnswer(question.type, answer.answer) }
                { answer.status === 'incorrect' && (
                    <><i className={`${styles.answerType} ${styles.correctAnswer}`}>Rätt svar</i>
                    { displayAnswer(question.type, getCorrectAnswer()) }</>
                ) }
            </div>
        </div>
    )
}

export default QuestionBox;
import { useEffect, useState } from 'react';
import styles from './Join.module.scss';
import NormalInput from '../../inputs/NormalInput';
import BoxButton from '../../buttons/BoxButton';
import ErrorMessage from '../../popups/message/ErrorMessage';
import WarningMessage from '../../popups/message/WarningMessage';

function JoinName({ onSubmitName, error, freezeMode }) {
    const [ name, setName ] = useState('');
    const [ loading, setLoading ] = useState(false);

    // Handle submit
    function handleSubmit() {
        if (name.length < 1) return;

        setLoading(true);
        onSubmitName(name);
    }

    // Handle enter press
    function handleEnterPress(e) {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    // Reset if error occurs
    useEffect(() => {
        if (error !== null) {
            setName('');
            setLoading(false);
        }
    }, [error]);

    // Component
    return (
        <div className={styles.form}>
            <h1>Gå med i läxförhör</h1>
            <div className={styles.nameInputWrapper}>
                { error && <ErrorMessage message={error} /> }
                <NormalInput
                    title="Förnamn och efternamn"
                    placeholder="Förnamn och efternamn"
                    type="text"
                    onChange={e => setName(e.target.value)}
                    value={name}
                    className={styles.nameInput}
                    autoFocus={true}
                    onKeyDown={handleEnterPress}
                />
                <WarningMessage message="Läxförhöret är låst, och du kan inte fortsätta om du lämnar fliken!" />
                <BoxButton
                    onClick={handleSubmit}
                    className={styles.sendButton}
                    loading={loading}
                    disabled={loading || name.length < 1}
                >Gå med</BoxButton>
            </div>
            <img className={styles.illustration} src="/assets/images/graphics/illustrations/lycka-till.webp" alt="Gå med i läxförhör" />
        </div>
    );
}

export default JoinName;
import Navbar from '../../components/navbars/Navbar';
import JoinCode from '../../components/tests/join/JoinCode';
import JoinName from '../../components/tests/join/JoinName';
import ContainerLg from '../../containers/ContainerLg';
import { useState } from 'react';
import axios from 'axios';

function Join() {
    const [ connected, setConnected ] = useState(false);
    const [ liveId, setLiveId ] = useState(null);
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ freezeMode, setFreezeMode ] = useState(false);

    // Handle code submit
    function handleSubmitCode(code) {
        setLoading(true);
        setError(null);

        axios.get(`https://api.studian.se/test/live/get-live-id-from-code?code=${code}`)
            .then(response => {
                setTimeout(() => {
                    setLiveId(response.data.live_id);
                    handleFreezeModeWarning(response.data.live_id);
                    setLoading(false);
                    setConnected(true); 
                }, 1000);
            })
            .catch(error => {
                setTimeout(() => {
                    setError(error.response?.data?.error || 'Något gick fel. Försök igen.');
                    setLoading(false);
                }, 1000);
            });
    }

    // Handle freeze mode warning
    function handleFreezeModeWarning(f_liveId) {
        axios.get(`https://api.studian.se/test/live/is-freeze-mode?live_id=${f_liveId}`)
            .then(response => {
                setFreezeMode(response.data.freeze_mode);
            })
            .catch(error => {
                console.error('Error with API call: ', error);
            });
    }

    // Handle name submit
    function handleSubmitName(name) {
        setLoading(true);
        setError(null);

        axios.post(`https://api.studian.se/test/student/join`, {
            'live_id': liveId,
            'name': name
        }, {
            withCredentials: true
        }).then(() => {
            setTimeout(() => {
                window.location.href = '/test/student';
            }, 500);
        }).catch(error => {
            setTimeout(() => {
                setError(error.response?.data?.error || 'Något gick fel. Försök igen.');
                setLoading(false);
            }, 500);
        });
    }

    return (
        <div>
            <Navbar />
            <ContainerLg centerContent={'true'}>
                <div>
                    {connected 
                        ? <JoinName onSubmitName={handleSubmitName} error={error} loading={loading} freezeMode={freezeMode} /> 
                        : <JoinCode onSubmitCode={handleSubmitCode} error={error} loading={loading} />}
                </div>
            </ContainerLg>
        </div>
    );
}

export default Join;
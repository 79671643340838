import styles from './ScoreTable.module.scss';
import Popup from 'reactjs-popup';
import { Check } from 'iconoir-react';

function ScoreCell({ value, rawValue, status, question }) {
    // Get preview content
    const getPreviewContent = () => {
        switch (question.type) {
            case 'exact':
                return (
                    <div className={styles.exactAnswer}>{value}</div>
                );

            case 'open':
                return (
                    <div className={styles.openAnswer}>{value}</div>
                );

            case 'multiple-choice':
                return (
                    <div className={styles.mcAnswer}><span className={styles.radio} />{value}</div>
                );

            case 'checkboxes':
                return (
                    rawValue.map((altValue) => {
                        return ( <div className={styles.checkboxAnswer}>
                            <span className={styles.checkbox}><Check /></span>
                            {question.alternatives[altValue]}
                        </div> );
                    })
                );

            default:
                return 'Ukendt typ';
        }
    }

    return (
        <Popup
            trigger={
                <td className={`${status === 'correct' && styles.correct} ${status === 'incorrect' && styles.incorrect}`}>
                    <span>{value}</span>
                </td>
            }
            on={['hover', 'focus']}
            contentStyle={{ maxWidth: '300px' }}
        >
            <div className={styles.answerPreview}>{getPreviewContent()}</div>
        </Popup>
    )
}

export default ScoreCell;
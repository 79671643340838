import { useState, useEffect } from 'react';
import axios from 'axios';

function AuthNot({ children, redirect }) {
    const [isAuthorized, setIsAuthorized] = useState(null);

    useEffect(() => {
        async function checkAuthorization() {
            try {
                const response = await axios.get('https://api.studian.se/auth/auth', { withCredentials: true });
                setIsAuthorized(response.data.authorized);

                if (response.data.authorized && redirect) {
                    window.location.href = redirect;
                }
            } catch (error) {
                // TODO: Add error box
                console.error('Error checking authorization:', error);
                setIsAuthorized(false);
            }
        }

        checkAuthorization();
    }, [ redirect ]);

    return isAuthorized ? (
        null
    ) : (
        <>{children}</>
    );
}

export default AuthNot;
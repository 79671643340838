import styles from './Done.module.scss';
import Navbar from '../../components/navbars/Navbar';
import ContainerLg from '../../containers/ContainerLg';

function Done() {
    return (
        <div>
            <Navbar />
                <ContainerLg centerContent>
                <div className={styles.done}>
                    <h1 className={styles.header}>Bra jobbat!</h1>
                    <p className={styles.paragraph}>Du är nu färdig med läxförhöret.</p>
                </div>
            </ContainerLg>
        </div>
    )
}

export default Done;
import styles from './Home.module.scss';
import ContainerLg from '../containers/ContainerLg';
import Navbar from '../components/navbars/Navbar';
import HomeHeader from '../components/headers/HomeHeader';
import HomeShortcuts from '../components/pages/home/shortcuts/HomeShortcuts';
import MainFooter from '../components/footers/MainFooter';
import BoxButton from '../components/buttons/BoxButton';
import LazyLoad from 'react-lazy-load';

function Home() {
    return (
        <div>
            <Navbar />
            <HomeHeader />
            <ContainerLg>
                <HomeShortcuts />
                <section className={styles.featureSections}>
                    <div className={styles.setsSection}>
                        <div className={styles.titleGraphics}>
                            <h1 className={styles.title}>Skapa engagerande läxor</h1>
                            <div className={styles.graphicsGrid}>
                                <span>
                                    <LazyLoad><img src="/assets/images/graphics/feature_sections/sets/set-flashcards.webp" alt="" /></LazyLoad>
                                    <span>Flashcards</span>
                                </span>
                                <span>
                                    <LazyLoad><img src="/assets/images/graphics/feature_sections/sets/set-write.webp" alt="" /></LazyLoad>
                                    <span>Skriv</span>
                                </span>
                                <span>
                                    <LazyLoad><img src="/assets/images/graphics/feature_sections/sets/set-match.webp" alt="" /></LazyLoad>
                                    <span>Matcha</span>
                                </span>
                                <span>
                                    <LazyLoad><img src="/assets/images/graphics/feature_sections/sets/set-games.webp" alt="" /></LazyLoad>
                                    <span>Spel</span>
                                </span>
                            </div>
                        </div>
                        <div className={styles.paragraphBox}>
                            <span className={styles.paragraph}>Motivation och engagemang är drivkraften för elever och deras prestation, därför kan du på Studian skapa interaktiva läxor online. Eleverna kan själva välja var, när och framför allt hur de vill plugga. Vi erbjuder en rad roliga och effektiva inlärningsmetoder som flashcards, skrivtest och spel. Läxorna är enkla att skapa, redigera och dela med elever och kollegor.</span>
                        </div>
                    </div>
                    <div className={styles.testsSection}>
                        <h1 className={styles.title}>Håll läxförhör online</h1>
                        <div className={styles.graphicsSubtitle}>
                            <LazyLoad><img src="/assets/images/graphics/feature_sections/tests/test-table.webp" alt="" /></LazyLoad>
                            <div className={styles.subtitle}>
                                <h2>Följ dina elever i realtid</h2>
                                <p>Hitta vanliga fel och skräddarsy din undervisning efter dina elevers kunskap och behov.</p>
                            </div>
                        </div>
                        <div className={styles.paragraphBox}>
                            <span className={styles.paragraph}>Skapa och håll i läxförhör live i klassrummet. Läxförhöret kan vara baserade på en tidigare läxa, eller skapade helt från grunden. Du kan välja om svaren på frågorna ska vara exakta, som i glosförhör, eller öppna där du som lärare bedömer. Läxförhören är minimalistiska och hjälper elever att hålla fokus och koncentrera sig på frågorna.</span>
                        </div>
                    </div>
                </section>
                <section className={styles.whySection}>
                    <LazyLoad><img src="/assets/images/graphics/illustrations/josefine/shrug.webp" alt="" /></LazyLoad>
                    <div className={styles.content}>
                        <h1 className={styles.title}>Varför Studian?</h1>
                        <p className={styles.paragraph}>Studian är det perfekta valet för alla som värdesätter ett innovativt och elevcentrerat lärande. Till skillnad från andra inlärningsplattformar har Studian utvecklats av ett team av erfarna elever och lärare, som förstår de utmaningar som både elever och pedagoger står inför i undervisningen. Vår plattform är utformad för att möta dessa utmaningar, med engagerande, anpassningsbara läxor som är både roliga och effektiva för elever i alla åldrar.</p>
                        <p className={styles.paragraph}>Men det är inte allt - Studian erbjuder också kraftfulla verktyg för lärare, vilket gör det möjligt för dig att hålla koll på dina elevers framsteg och skräddarsy din undervisning efter deras individuella behov. Så oavsett om du är en lärare som vill utveckla dina lektioner eller en elev som söker ett mer engagerande och effektivt sätt att lära sig på, så är Studian det perfekta valet - utvecklat av elever och lärare, för elever och lärare.</p>
                    </div>
                </section>
                <section className={styles.startSection}>
                    <div className={styles.buttonWrapper}>
                        <BoxButton onClick={() => window.location.href = '/signup'}>Är du redo? Kom igång direkt!</BoxButton>
                    </div>
                </section>
            </ContainerLg>
            <MainFooter />
        </div>
    );
}

export default Home;
import styles from './Save.module.scss';
import Auth from '../../components/auth/Auth';
import ContainerLg from '../../containers/ContainerLg';
import { useParams } from "react-router-dom";
import Navbar from '../../components/navbars/Navbar';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Section from '../../containers/Section';
import ScoreTable from '../../components/tests/table/ScoreTable';
import QuestionBox from '../../components/tests/saves/QuestionBox';
import { SyncLoader } from 'react-spinners';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ErrorMessage from '../../components/popups/message/ErrorMessage';

function Save() {
    const { save_id } = useParams();
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(null);
    const [ saveData, setSaveData ] = useState(null);
    const [ displayType, setDisplayType ] = useState('table');
    const [ selectedStudent, setSelectedStudent ] = useState(null);
    const [ possibleStudents, setPossibleStudents ] = useState([]);
    const [ studentQuery, setStudentQuery ] = useState(null);
    const [ studentScore, setStudentScore ] = useState(0);

    // ----------
    //  API call
    // ----------

    useEffect(() => {
        axios.get(`https://api.studian.se/test/save/get/data?save_id=${save_id}`)
            .then(response => {
                setSaveData(response.data.save);
                setSelectedStudent(response.data.save.students[0]);
                setStudentQuery(response.data.save.students[0].name);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching save data:', error);
                setError(error.response?.data?.error || 'Något gick fel. Försök igen.');
            });
    }, [save_id]);

    // -------
    //  Table
    // -------
    const table = () => {
        return (
            <ScoreTable 
                questions={saveData.questions}
                studentData={saveData.students}
                classPercentageArray={[]}
            />
        );
    }

    // ---------
    //  Student
    // ---------

    // Search and select student
    const searchStudent = (e) => {
        const query = e.target.value;
        setStudentQuery(query);

        if (query === '') {
            setPossibleStudents([]);
            return;
        }

        const filteredStudents = saveData.students.filter(student => student.name.toLowerCase().includes(query.toLowerCase()));
        setPossibleStudents(filteredStudents);
    }

    const selectStudentWithEnter = (e, studentId) => {
        if (e.key === 'Enter') {
            if (studentId === undefined && possibleStudents.length > 0) {
                studentId = possibleStudents[0].id;
            }

            selectStudent(studentId);
        }
    }

    const selectStudent = (studentId) => {
        const selectedStudentObject = saveData.students.find(student => student.id === studentId);

        setSelectedStudent(selectedStudentObject);
        setPossibleStudents([]);
        setStudentQuery(selectedStudentObject.name);
    }

    // Student answers
    useEffect(() => {
        if (!selectedStudent) return;

        // Update score
        let score = 0;
        selectedStudent.answers.map(answer => answer.status === 'correct' ? score += 1 : score += 0);
        setStudentScore(score);
    }, [ selectedStudent ]);

    const studentAnswers = () => {
        return (
            <div className={styles.studentAnswers}>
                <div className={styles.title}>
                    <h2 className={styles.name}>{selectedStudent.name}</h2>
                    <h2 className={styles.score}>{studentScore}/{saveData.questions.length}</h2>
                </div>
                <div className={styles.questionBoxWrapper}>
                    {selectedStudent.answers.map((answer, index) => (
                        <QuestionBox 
                            keyProp={index}
                            question={saveData.questions[index]}
                            answer={answer}
                            studentName={selectedStudent.name}
                        />
                    ))}
                </div>
            </div>
        );
    }

    // Component
    return (
        <Auth redirect="/login">
            <Navbar />
            <ContainerLg>
                <Section>
                    { error && <ErrorMessage message={error} /> }
                    <div className={styles.title}>
                        { saveData !== null ? (
                            <><h1 className={styles.heading}>Läxförhör: {saveData.test_name}</h1>
                            <span className={styles.date}>{saveData.created_readable}</span></>
                        ) : (
                            <><Skeleton width={300} height={50} />
                            <Skeleton width={200} height={30} /></>
                        ) }
                    </div>
                    { !loading && (
                        <><div className={styles.testSave}>
                            <div className={styles.navigation}>
                                <div className={styles.displayType}>
                                    <span 
                                        className={`${styles.type} ${displayType === 'table' ? styles.selected : ''}`}
                                        onClick={() => setDisplayType('table')}
                                    >Tabell</span>
                                    <span 
                                        className={`${styles.type} ${displayType === 'student' ? styles.selected : ''}`}
                                        onClick={() => setDisplayType('student')}
                                    >Elev</span>
                                </div>
                                {  displayType === 'student' && (
                                    <div className={styles.selectStudent}>
                                        <span>Elev:</span>
                                        <div className={styles.selectStudentInputContainer}>
                                            <input 
                                                type="text" 
                                                placeholder='Välj elev...' 
                                                className={`${styles.selectStudentInput} ${possibleStudents.length > 0 ? styles.active : ''}`} 
                                                value={studentQuery} 
                                                onChange={searchStudent}
                                                onKeyDown={(e) => selectStudentWithEnter(e, undefined) }
                                            ></input>
                                            <div className={styles.selectStudentDropdown}>
                                                { possibleStudents.length > 0 && possibleStudents.map((student, index) => {
                                                    return (
                                                        <span 
                                                            className={styles.selectStudentDropdownItem}
                                                            key={student.id}
                                                            onClick={() => selectStudent(student.id)}
                                                            tabIndex="0"
                                                            role="button"
                                                            onKeyDown={(e) => selectStudentWithEnter(e, student.id)}
                                                        >{student.name}</span>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            { displayType === 'table' && (
                                <div className={styles.tableWrapper}>
                                    { table() }
                                </div>
                            ) }
                            { displayType === 'student' && studentAnswers() }
                        </div></>
                    ) }
                    { loading && (
                        <div className={styles.loading}>
                            <span><SyncLoader loading={loading} size={15} color="#FE654F" /></span>
                        </div>
                    ) }
                </Section>
            </ContainerLg>
        </Auth>
    );
}

export default Save;
import styles from '../../create/test/CreateTest.module.scss';
import Navbar from "../../../components/navbars/Navbar";
import Auth from "../../../components/auth/Auth";
import ContainerSm from '../../../containers/ContainerSm';
import Section from '../../../containers/Section';
import CreateTestForm from '../../../components/pages/create/tests/CreateTestForm';
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import ErrorMessage from "../../../components/popups/message/ErrorMessage";
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

function EditTest() {
    const generateSSID = require('ssid');
    const [ error, setError ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ testData, setTestData ] = useState(null);
    const createTestFormRef = useRef(null);

    // Get test data
    const testId = useLocation().pathname.split('/')[3] || '';

    useEffect(() => {
        setLoading(true);
        axios.get(`https://api.studian.se/test/get/data?test_id=${testId}`)
            .then(response => {
                setTestData(response.data?.test || {});
                setLoading(false);
            })
            .catch(error => {
                setError(error.response?.data?.error || 'Något gick fel. Försök igen.');
                setLoading(false);
            });
    }, [ testId ]);

    // Give questions SSIDs
    useEffect(() => {
        if (testData === null) return;
        testData.questions.forEach(question => {
            question.ssid = generateSSID(8);
        });
    }, [ testData, generateSSID ]);

    // Handle submit
    const handleSubmit = () => {
        setLoading(true);
        const questionsData = createTestFormRef.current.gatherQuestionsData();
        const testName = createTestFormRef.current.getTestName();
        const freezeMode = createTestFormRef.current.getFreezeMode();
        const showResults = createTestFormRef.current.getShowResults();
        
        // Call the API and create the test
        axios.post('https://api.studian.se/test/manage/edit', {
            test_id: testId,
            name: testName,
            freeze_mode: freezeMode ? true : false,
            show_results: showResults ? true : false,
            questions: questionsData
        }, {
            withCredentials: true
        }).then(() => {
            window.location.href = '/dashboard/tests';
        }).catch(error => {
            setLoading(false);
            const message = error.response?.data?.error || 'Något gick fel. Kontakta oss om felet kvarstår!';
            setError(message);
        });
    }

    return (
        <div>
            <Auth redirect="/login">
                <Navbar />
                <ContainerSm>
                    <Section>
                        <div className={styles.header}>
                            <h2>Redigera läxförhör</h2>
                            <PrimaryButton onClick={handleSubmit} loading={loading} disabled={loading}>Spara</PrimaryButton>
                        </div>
                        { error && <ErrorMessage message={error} /> }
                        { testData && (
                            <CreateTestForm 
                                ref={createTestFormRef} 
                                defaultName={ testData.name} 
                                defaultFreezeMode={ testData.freeze_mode } 
                                defaultShowResults={ testData.show_results } 
                                defaultQuestions={ testData.questions } 
                            />
                        ) }
                    </Section>
                </ContainerSm>
            </Auth>
        </div>
    );
}

export default EditTest;
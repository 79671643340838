import styles from './Navbar.module.scss';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import Auth from '../auth/Auth';
import AuthNot from '../auth/AuthNot';
import PfpMedium from '../user/PfpMedium';
import ProfileMenu from './menus/ProfileMenu';
import CreateMenu from './menus/CreateMenu';
import { useState } from 'react';
import { Menu, Xmark } from 'iconoir-react';

function Navbar() {
    const [ profileMenuOpen, setProfileMenuOpen ] = useState(false);
    const [ hamburgerMenuOpen, setHamburgerMenuOpen ] = useState(false);

    function toggleProfileMenu() {
        setProfileMenuOpen(!profileMenuOpen);
    }

    function toggleHamburgerMenu() {
        setHamburgerMenuOpen(!hamburgerMenuOpen);
    }

    return (
        <>
            <nav className={styles.navbar}>
                <div className={styles.content}>
                    <a href="/">
                        <img className={styles.logo} src="/assets/images/logos/logo-1200px.svg" alt="Studian" />
                    </a>
                    <Auth>
                        <CreateMenu triggerClass={styles.create} />
                        <div className={styles.profile} onClick={ toggleProfileMenu }>
                            <PfpMedium />
                        </div>
                    </Auth>
                    <AuthNot>
                        <div className={styles.links}>
                            <SecondaryButton onClick={() => { window.location.href = '/login'}}>Logga in</SecondaryButton>
                            <PrimaryButton onClick={() => { window.location.href = '/signup'}}>Skapa konto</PrimaryButton>
                        </div>
                        { hamburgerMenuOpen 
                            ? <Xmark className={styles.hamburger} onClick={ toggleHamburgerMenu } /> 
                            : <Menu className={styles.hamburger} onClick={ toggleHamburgerMenu } /> 
                        }
                    </AuthNot>
                </div>
            </nav>
            <Auth>
                <ProfileMenu open={profileMenuOpen} />
            </Auth>
            <AuthNot>
                <div className={`${styles.hamburgerMenu} ${hamburgerMenuOpen ? styles.open : ''}`}>
                    <SecondaryButton onClick={() => { window.location.href = '/login'}}>Logga in</SecondaryButton>
                    <PrimaryButton onClick={() => { window.location.href = '/signup'}}>Skapa konto</PrimaryButton>
                </div>
            </AuthNot>
        </>
    );
}

export default Navbar;
import styles from './ContainerSm.module.scss';

function ContainerSm({ children, centerContent }) {
    return (
        <div className={`${styles.container} ${centerContent && styles.centerContent}`}>
            {children}
        </div>
    );
}

export default ContainerSm;
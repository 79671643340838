import styles from './ScoreTable.module.scss';
import ScoreCell from './ScoreCell';
import Popup from 'reactjs-popup';
import { useState, useEffect } from 'react';
import { SnowFlake } from 'iconoir-react';
import PrimaryButton from '../../buttons/PrimaryButton';
import axios from 'axios';

function ScoreRow({ student, questions }) {
    const [ answers, setAnswers ] = useState([]);
    const [ loadingUnfreeze, setLoadingUnfreeze ] = useState(false);

    // Get answers
    useEffect(() => {
        setAnswers(Array.isArray(student.answers) ? student.answers : []);
    }, [student]);

    // Get current answer
    const getCurrentAnswer = (index) => {
        switch (questions[index].type) {
            case 'exact':
                return answers[index].answer[0];

            case 'open':
                return answers[index].answer[0];

            case 'multiple-choice':
                return questions[index].alternatives[answers[index].answer];

            case 'checkboxes':
                const selectedAlternatives = answers[index].answer; 
                let currentAnswer = '';
                selectedAlternatives.forEach(selectedAlternative => {
                    currentAnswer += questions[index].alternatives[selectedAlternative] + ', ';
                });
                return currentAnswer;

            default:
                return '';
        }
    }

    // Unfreeze student
    const unfreezeStudent = () => {
        setLoadingUnfreeze(true);

        axios.put('https://api.studian.se/test/live/unfreeze-student', {
            student_id: student.id
        }, {
            withCredentials: true
        }).finally(() => {
            setLoadingUnfreeze(false);
        });
    }

    // Component
    return (
        <tr className={styles.studentRow}>
            <td className={styles.studentName}>
                {student.name}
                { !student.done ?
                    <Popup
                        trigger={
                            <span className={styles.status}></span>
                        }
                        position={'bottom center'}
                        on={['hover', 'focus']}
                        closeOnDocumentClick
                    >
                        <span>Eleven är inte färdig</span>
                    </Popup>
                    : <Popup
                        trigger={
                            <span className={`${styles.status} ${styles.done}`}></span>
                        }
                        position={'bottom center'}
                        on={['hover', 'focus']}
                        closeOnDocumentClick
                    >
                        <span>Eleven är färdig</span>
                    </Popup>
                }
                { student.frozen ?
                    <Popup
                        trigger={
                            <SnowFlake className={styles.frozenIcon} />
                        }
                        position={'bottom center'}
                        on={['hover', 'focus']}
                        closeOnDocumentClick
                    >
                        <div className={styles.frozenPopup}>
                            <span>Eleven är fryst</span>
                            <PrimaryButton onClick={unfreezeStudent} loading={loadingUnfreeze}>Släpp elev</PrimaryButton>
                        </div>
                    </Popup> : ''
                }
            </td>
            <td>{student.score}%</td>
            {answers.map((answer, index) => (
                <ScoreCell key={index} value={getCurrentAnswer(index)} rawValue={answer.answer} question={questions[index]} status={answer.status} />
            ))}
        </tr>
    );
}

export default ScoreRow;
import Menu from "../../popups/menus/Menu";
import { BookmarkBook, ClipboardCheck, PlusCircle } from "iconoir-react";

function CreateMenu({ triggerClass }) {
    return (
        <Menu
            triggerElement={
                <PlusCircle className={triggerClass} />
            }
            position="bottom right"
            items={[
                {
                    label: 'Ny läxa',
                    icon: <BookmarkBook />,
                    onClick: () => { window.location.href = '/create/set' }
                },
                {
                    label: 'Nytt läxförhör',
                    icon: <ClipboardCheck />,
                    onClick: () => { window.location.href = '/create/test' }
                }
            ]}
        />
    )
}

export default CreateMenu;
import Navbar from "../components/navbars/Navbar";
import Sidebar from "../components/pages/dashboard/Sidebar";
import DashboardContentWrapper from "../components/pages/dashboard/DashboardContentWrapper";
import { Routes, Route, useLocation } from "react-router-dom";
import SetScreen from "../components/pages/dashboard/screens/SetScreen";
import TestScreen from "../components/pages/dashboard/screens/TestScreen";
import Auth from "../components/auth/Auth";
import { useState } from "react";

// TODO: ARIA labels for screen readers
// TODO: Keyboard navigation

function Dashboard() {
    const location = useLocation();
    const page = location.pathname.split('/')[2] || 'sets';
    const [ sidebarOpen, setSidebarOpen ] = useState(false);

    return (
        <div>
            <Auth redirect="/login">
                <Navbar />
                <Sidebar selected={page} open={sidebarOpen} onSidebarClose={() => setSidebarOpen(false)} />
                <DashboardContentWrapper sidebarOpen={sidebarOpen}>
                    <Routes>
                        <Route path="/" element={<SetScreen onSidebarOpen={() => setSidebarOpen(true)} />} />
                        <Route path="/sets" element={<SetScreen onSidebarOpen={() => setSidebarOpen(true)} />} />
                        <Route path="/tests" element={<TestScreen onSidebarOpen={() => setSidebarOpen(true)} />} />
                    </Routes>
                </DashboardContentWrapper>
            </Auth>
        </div>
    );
}

export default Dashboard;
import styles from './TestCard.module.scss';
import { EditPencil, Play, ClipboardCheck, OpenInWindow } from 'iconoir-react';
import { useState } from 'react';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Popup from 'reactjs-popup';
import TestSavesModal from '../../tests/saves/TestSavesModal';
import { SyncLoader } from 'react-spinners';
import DeleteTestModal from '../modals/DeleteTestModal';

function TestCard({ testData, testSaves, refreshTests, refreshTestSaves }) {
    const [ loading, setLoading ] = useState(false);

    // Handle API calls
    function handleStartTest() {
        setLoading(true);

        axios.put('https://api.studian.se/test/live/start-test', {
            test_id: testData.id
        }, {
            withCredentials: true
        }).then(response => {
            window.location.href = `/test/host/${response.data.live_id}`;
        }).catch(error => {
            console.error('Error with API call: ', error);
            setLoading(false);
        });
    }

    return (
        <div 
            className={styles.card} 
            tabIndex={0}
        >
            <div className={styles.body}>
                <div className={styles.header}>
                    <ClipboardCheck className={styles.icon} />
                    <div className={styles.titles}>
                    { testData ? 
                        <>
                            <span className={styles.title}>{ testData.name }</span>
                            <span className={styles.date}>Skapad { testData.created_readable }</span>
                        </>
                        : <Skeleton width={200} />
                    }
                    </div>
                </div>
                <div className={styles.actions}>
                    { !loading
                        ? <div className={styles.liveActions}>
                            { testData?.live === 1 || false ? (
                                <>
                                    <span className={styles.live}>LIVE</span>
                                    <Popup
                                        trigger={
                                            <a href={`/test/host/${testData.live_id ?? ''}`}>
                                                <OpenInWindow />
                                            </a>
                                        }
                                        position={'bottom center'}
                                        on={['hover', 'focus']}
                                        closeOnDocumentClick
                                    >
                                        <span style={{textAlign: 'center'}}>Öppna läxförhör</span>
                                    </Popup>
                                </>
                            ) : (
                                <>
                                    <Popup
                                        trigger={<Play onClick={handleStartTest} />}
                                        position={'bottom center'}
                                        on={['hover', 'focus']}
                                        closeOnDocumentClick
                                    >
                                        <span style={{textAlign: 'center'}}>Starta</span>
                                    </Popup>
                                    <TestSavesModal testSaves={testSaves} refreshTestSaves={refreshTestSaves} />
                                    <Popup
                                        trigger={
                                            <a href={`/edit/test/${testData.id ?? ''}`}>
                                                <EditPencil />
                                            </a>
                                        }
                                        position={'bottom center'}
                                        on={['hover', 'focus']}
                                        closeOnDocumentClick
                                    >
                                        <span style={{textAlign: 'center'}}>Redigera</span>
                                    </Popup>
                                    <DeleteTestModal testId={testData.id} onDelete={refreshTests} />
                                </>
                            ) }
                        </div>
                        : <SyncLoader color="#FE654F" size={10} />
                    }
                </div>
            </div>
        </div>
    );
}

export default TestCard;
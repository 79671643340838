import styles from './Error.module.scss';
import Navbar from '../../components/navbars/Navbar';
import LazyLoad from 'react-lazy-load';

function NotFound() {
    return (
        <div>
            <Navbar />
            <div className={styles.errorPage}>
                <div className={styles.errorPageImage}>
                    <LazyLoad>
                        <img src="/assets/images/graphics/error_pages/404.webp" alt="" />
                    </LazyLoad>
                </div>
                <div className={styles.errorPageText}>
                    <span className={styles.errorPageError}>Error 404</span>
                    <h1 className={styles.errorPageTitle}>Du har gått vilse!</h1>
                    <p className={styles.errorPageParagraph}>Men ta det lugnt, vi hjälper dig att hitta rätt! Klicka <a href="/">här</a> för att komma tillbaka till förstasidan.</p>
                </div>
            </div>
        </div>
    );
}

export default NotFound;
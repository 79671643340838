import styles from './Section.module.scss';

function Section({ children, centerContent }) {
    return (
        <section className={`${styles.section} ${centerContent && styles.centerContent}`}>
            {children}
        </section>
    );
}

export default Section;
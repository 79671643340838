import styles from './Sidebar.module.scss';
import { BookmarkBook, ClipboardCheck, QuoteMessage, Cloud, Community, Trophy, StatsUpSquare, Xmark } from 'iconoir-react';

function Sidebar({ selected, open, onSidebarClose }) {
    return (
        <div className={`${styles.sidebar} ${open ? styles.open : ''}`}>
            <Xmark className={styles.close} onClick={onSidebarClose} />
            <a href="/dashboard" className={`${styles.item} ${selected === 'sets' ? styles.selected : ''}`}>
                <BookmarkBook className={styles.icon} />
                <span className={styles.title}>Läxor</span>
            </a>
            <a href="/dashboard/tests" className={`${styles.item} ${selected === 'tests' ? styles.selected : ''}`}>
                <ClipboardCheck className={styles.icon} />
                <span className={styles.title}>Läxförhör</span>
            </a>
            <a href="/dashboard/mindmirrors" className={`${styles.item} ${selected === 'mindmirrors' ? styles.selected : ''} ${styles.commingSoon}`}>
                <QuoteMessage className={styles.icon} />
                <span className={styles.title}>Tankespeglar</span>
            </a>
            <a href="/dashboard/mindclouds" className={`${styles.item} ${selected === 'mindclouds' ? styles.selected : ''} ${styles.commingSoon}`}>
                <Cloud className={styles.icon} />
                <span className={styles.title}>Tankemoln</span>
            </a>
            <a href="/dashboard/quizes" className={`${styles.item} ${selected === 'quizes' ? styles.selected : ''} ${styles.commingSoon}`}>
                <Trophy className={styles.icon} />
                <span className={styles.title}>Quiz</span>
            </a>
            <a href="/dashboard/polls" className={`${styles.item} ${selected === 'polls' ? styles.selected : ''} ${styles.commingSoon}`}>
                <StatsUpSquare className={styles.icon} />
                <span className={styles.title}>Omröstning</span>
            </a>
            <a href="/dashboard/classes" className={`${styles.item} ${selected === 'classes' ? styles.selected : ''} ${styles.commingSoon}`}>
                <Community className={styles.icon} />
                <span className={styles.title}>Klasser</span>
            </a>
        </div>
    );
}

export default Sidebar;
import styles from './GradientContainer.module.scss';

function GradientContainer({ children, centerContent }) {
    return (
        <div className={`${styles.container} ${centerContent && styles.centerContent}`}>
            {children}
        </div>
    );
}

export default GradientContainer;
import styles from './Results.module.scss';
import ContainerLg from '../../containers/ContainerLg';
import Navbar from '../../components/navbars/Navbar';
import Section from '../../containers/Section';
import { useState, useEffect } from 'react';
import axios from 'axios';
import ErrorMessage from '../../components/popups/message/ErrorMessage';
import { SyncLoader } from 'react-spinners';
import QuestionBox from '../../components/tests/saves/QuestionBox';

function Results() {
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState('');
    const [ answers, setAnswers ] = useState(null);
    const [ questions, setQuestions ] = useState(null);
    const [ correctAnswerNumber, setCorrectAnswerNumber ] = useState(null);

    useEffect(() => {
        setLoading(true);
        axios.get('https://api.studian.se/test/student/get-results', {
            withCredentials: true
        }).then(response => {
            setAnswers(response.data.student_answers);
            setQuestions(response.data.test_questions);
            setLoading(false);

            let correctAnswers = 0;
            response.data.student_answers.forEach(answer => {
                if (answer.status !== 'incorrect') {
                    correctAnswers++;
                }
            });

            setCorrectAnswerNumber(correctAnswers);
        }).catch(error => {
            setError(error.response?.data?.error || 'Något gick fel. Försök igen.');
            setLoading(false);
        });
    }, []);

    return (
        <div>
            <Navbar />
            <ContainerLg>
                <Section>
                    { correctAnswerNumber && <h1 className={styles.header}>Du fick {correctAnswerNumber} av {answers.length} rätt!</h1> }
                    { error && <ErrorMessage message={error} /> }
                    { loading && <div className={styles.loading}><SyncLoader size={15} color="#FE654F" /></div> }
                    <div className={styles.questionWrapper}>
                        { answers && answers.map((answer, index) => (
                            <QuestionBox 
                                question={questions[index]}
                                answer={answer}
                                secondPerson={true}
                                key={index}
                                keyProp={index}
                            />
                        ))}
                    </div>
                </Section>
            </ContainerLg>
        </div>
    )
}

export default Results;
import styles from './DashboardContentWrapper.module.scss';

function DashboardContentWrapper({ children, sidebarOpen }) {
    return (
        <div className={`${styles.wrapper} ${sidebarOpen ? styles.sidebarOpen : ''}`}>
            {children}
        </div>
    );
}

export default DashboardContentWrapper;